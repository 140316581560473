.CommunityTrust {
  margin: clamp(16px, calc(20vw / 7.68), 28px) 0;
  padding: 6px 20px 6px 8px;
  border-radius: 30px;
  background-color: var(--primary-400, #ffd7e4);
  display: grid;
  grid-template-columns: auto 1fr;
  width: fit-content;
  align-items: center;
  gap: 12px;
  .images {
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      object-fit: cover;
      border: 2px solid var(--primary-300, #fff6f9);
      &:not(:first-child) {
        margin-left: -23px;
      }
    }
  }
  .title {
    font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
    color: var(--secondary-600, #504752);
    strong {
      display: block;
      font-weight: normal;
      font-size: calc(18em / 14);
    }
  }
}
