.Gallery {
  min-width: 0;
  max-width: 485px;
  width: 100%;
  margin: 0 auto;
  .preview {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    img {
      aspect-ratio: 604/758;
      object-fit: cover;
    }
  }
  .thumbnails {
    margin-top: 12px;
    gap: 12px;
    display: flex;
    overflow: auto;
    button {
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: var(--primary-600, #f489a9);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: transform 0.3s var(--easing);
      }
      &[aria-current='true'] {
        img {
          transform: translateY(-3px);
        }
        &::before {
          transform: scaleY(1);
        }
      }
      img {
        width: clamp(52px, calc(80vw / 7.68), 80px);
        height: clamp(52px, calc(80vw / 7.68), 80px);
        object-fit: cover;
        transition: transform 0.3s var(--easing);
      }
    }
  }
  &:hover,
  &:focus-within {
    .navigation {
      transform: scale(1);
      opacity: 1;
    }
  }
  .navigation {
    position: absolute;
    top: calc(50% - 22px);
    width: 44px;
    height: 44px;
    display: grid;
    place-items: center;
    border-radius: 50px;
    background: var(--primary-200, #fff6f9);
    border: 1px solid var(--primary-400);
    transition: transform 0.3s var(--easing), opacity 0.3s var(--easing);
    transform: scale(0.9);
    opacity: 0;
    &:active {
      transform: scale(0.95);
      transition: none;
    }
    &.prev {
      left: 8px;
    }
    &.next {
      right: 8px;
    }
  }
}

.RecentPurchases {
  position: absolute;
  left: 13px;
  right: 13px;
  margin: 0 auto;
  bottom: 13px;
  max-width: fit-content;
  font-size: clamp(13px, calc(15vw / 7.68), 15px);
  border-radius: 4px;
  background: var(--secondary-400, #97879d);
  color: var(--primary-100, #fff);
  padding: clamp(8px, calc(12vw / 7.68), 12px) clamp(12px, calc(16vw / 7.68), 16px);
  text-align: center;
  animation: RecentPurchases 8s var(--easing) both;
  @keyframes RecentPurchases {
    0%,
    100% {
      opacity: 0;
      transform: translateY(13px);
    }
    10%,
    90% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
