.embla {
  margin: auto;
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
