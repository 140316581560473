.YummyWishes {
  &:not(:first-child) {
    margin-top: clamp(42px, calc(48vw / 7.68), 64px);
  }
  &:not(:last-child) {
    margin-bottom: clamp(42px, calc(48vw / 7.68), 64px);
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: clamp(32px, calc(48vw / 7.68), 48px);
  align-items: center;
  header {
    h2 {
      margin-bottom: clamp(12px, calc(16vw / 7.68), 16px);
    }
  }
}
