.OrderedList {
  @media (min-width: 450px) {
    columns: 2;
  }
  &:not(:last-child) {
    margin-bottom: clamp(28px, calc(32vw / 7.68), 42px);
  }
  li {
    break-inside: avoid;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    &:not(:last-child) {
      margin-bottom: clamp(12px, calc(16vw / 7.68), 16px);
    }
    p {
      margin-top: 6px;
    }
  }
}
