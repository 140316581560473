.Product {
  display: grid;
  gap: clamp(32px, calc(44vw / 7.68), 64px) 44px;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    @media (min-height: 500px) {
      .Info {
        position: sticky;
        top: calc(clamp(60px, calc(76vw / 7.68), 80px) + 21px);
      }
    }
  }
}
